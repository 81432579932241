import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'
const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleNav = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            <header className="overlay-black">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="menu">
                                <div className="brand-logo">
                                    <Link to="/">
                                        <img
                                            src="../assets/images/icon/logo13.png"
                                            alt=""
                                            className="img-fluid lazyload"
                                            style={{ height: 66, width: 198 }}
                                        />
                                    </Link>
                                </div>
                                <nav>
                                    <div className="main-navbar">
                                        <div id="mainnav">
                                            <div className="toggle-nav" onClick={handleToggleNav}>
                                                <i className="fa fa-bars sidebar-bar" />
                                            </div>
                                            <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`} />
                                            <ul className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                                                <li className="back-btn">
                                                    <div className="mobile-back text-end" onClick={handleToggleNav}>
                                                        <span>Back</span>
                                                        <i
                                                            aria-hidden="true"
                                                            className="fa fa-angle-right ps-2"
                                                        />
                                                    </div>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/" className="nav-link menu-title">
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/About" className="nav-link menu-title">
                                                        About Us
                                                    </Link>
                                                </li>

                                                <li className="mega-menu">
                                                    <Link to="/Experiences" className="nav-link menu-title">
                                                        Experiences
                                                    </Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/Accommodation" className="nav-link menu-title">
                                                        Accommodation
                                                    </Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/Dining" className="nav-link menu-title">
                                                        Dining
                                                    </Link>
                                                </li>
                                                <li className="dropdown">
                                                    <Link to="/Orchid" className="nav-link menu-title">
                                                        THE ORCHARD
                                                    </Link>
                                                </li>
                                                <li className="mega-menu">
                                                    <Link to="/Packages" className="nav-link menu-title">
                                                        Package
                                                    </Link>
                                                </li>

                                                <li className="dropdown">
                                                    <Link to="/Gallery" className="nav-link menu-title">
                                                        Gallery
                                                    </Link>
                                                </li>

                                                <li className="dropdown">
                                                    <Link to="/Profilelogin" className="nav-link menu-title">
                                                        <i className="fas fa-user"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
